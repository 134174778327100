<template>
   <div class="SwitchPage">
        <el-card>
             <p style="font-size:16px;color:#888;">企业切换成功</p> <el-button @click="$router.go(-1)">返回</el-button>
        </el-card>
   </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

</style>